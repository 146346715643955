document.addEventListener('DOMContentLoaded', function () {
  if (getCookie('policy_accepted') !== 'policy_accepted') {
    document.getElementById('consenso').classList.remove('d-none')
    document.getElementById('accept-cookie').onclick = function () {
      acceptCookie()
      initChat()
    }
  }else{
    initChat()
  }

})

function initChat(){
  window.formbutton = window.formbutton || function () {(formbutton.q = formbutton.q || []).push(arguments)}
  formbutton('create', {
    action: "https://formspree.io/f/myyorqbw",
    title: '👋🏼 Entriamo in contatto!',
    fields: [
      {
        type: 'text',
        label: 'Nome:',
        name: 'name',
        required: true,
        placeholder: 'Nome'
      },
      {
        type: 'email',
        label: 'Email:',
        name: 'email',
        required: true,
        placeholder: 'tua@email.com'
      },
      {
        type: 'textarea',
        label: 'Messaggio:',
        name: 'message',
        placeholder: 'Di cosa hai bisogno?',
      },
      {
        type: 'submit',
        value: 'Invia',
      }
    ],
  })
}

function getCookie (cname) {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function acceptCookie () {
  document.getElementById('consenso').classList.add('d-none')
  const d = new Date()
  d.setTime(d.getTime() + (60 * 24 * 60 * 60 * 1000))
  let expires = 'expires=' + d.toUTCString()
  document.cookie = 'policy_accepted' + '=' + 'policy_accepted' + ';' + expires + ';path=/'
}

let mqcheck

function midiaq () {
  mqcheck = window.matchMedia('(min-width: 768px)').matches
  if (mqcheck) {
    if (document.getElementById('collapse1') !== null) {
      document.getElementById('collapse1').classList.add('show')
      document.getElementById('collapse2').classList.add('show')
      document.getElementById('collapse3').classList.add('show')
    }
  } else if (document.getElementById('collapse1') !== null) {
    if (document.getElementById('collapse1').classList.contains('show')) {
      document.getElementById('collapse1').classList.remove('show')
      document.getElementById('collapse2').classList.remove('show')
      document.getElementById('collapse3').classList.remove('show')
    }
  }
  let mqcheck2 = window.matchMedia('(min-width: 992px)').matches
  if (mqcheck2) {
    if (document.getElementById('altezza') !== null) {
      const els = document.getElementsByClassName('altezza')
      for (let i = 0; i < els.length; i++) {
        els[i].classList.add('min-vh-100')
      }
    }
  } else {
    if (document.getElementById('collapse1') !== null) {
      if (document.getElementById('altezza') !== null) {
        const els = document.getElementsByClassName('altezza')
        for (let i = 0; i < els.length; i++) {
          els[i].classList.remove('min-vh-100')
        }
      }
    }
  }
}

//midiaquery
mqcheck = Boolean(false)

midiaq()

window.addEventListener('resize', midiaq)


